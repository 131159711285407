<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("EQUIPMENTS.ADD_EQUIPMENT") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button @click="goBack" type="button" class="btn btn-sm btn-primary">
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <equipment-form
              :loading="loading"
              :equipmentData="equipment"
              :formErrors="formErrors"
              @equipmentSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultEquipment from "./defaultEquipment";
import EquipmentForm from "./partials/EquipmentForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    EquipmentForm,
  },

  mixins: [alertLeave],

  data() {
    const equipment = cloneDeep(defaultEquipment);
    return {
      equipment: equipment,
      formErrors: null,
      loading: false,
    };
  },

  methods: {
    goBack() {
      this.$router.push({ name: "List Equipments" });
    },

    viewEquipment(equipment) {
      this.alertLeave = false;
      this.$router.push({
        name: "View Equipment",
        params: { id: equipment.id },
      });
    },

    async handleSubmit(equipment) {
      this.loading = true;
      const equipmentData = cloneDeep(equipment);
      if (equipmentData.cafeteria) {
        if (!equipmentData.cafeteria.id) {
          delete equipmentData.cafeteria;
        }
      }
      if (equipmentData.serviceCenter) {
        if (!equipmentData.serviceCenter.id) {
          delete equipmentData.serviceCenter;
        }
      }
      if (equipmentData.establishment) {
        if (!equipmentData.establishment.id) {
          delete equipmentData.establishment;
        }
      }
      if (equipmentData.cafeteria) {
        if (!equipmentData.cafeteria.id) {
          delete equipmentData.cafeteria;
        }
      }
      if (equipmentData.categoryEquipment) {
        if (!equipmentData.categoryEquipment.id) {
          delete equipmentData.categoryEquipment;
        }
      }
      if (equipmentData.supplier) {
        if (!equipmentData.supplier?.id) {
          equipmentData.supplier = null;
        }
      }

      try {
        await this.$store.dispatch("equipments/add", equipmentData);
        this.$notify({
          type: "success",
          message: this.$t("EQUIPMENTS.EQUIPMENT_ADDED"),
        });
        const equipment = await this.$store.getters["equipments/equipment"];
        this.viewEquipment(equipment);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        const equipment = await this.$store.getters["equipments/equipment"];
        if (equipment.id) {
          await this.$store.dispatch("equipments/destroy", equipment.id);
        }
        this.loading = false;
      }
    },
  },
};
</script>
